<template>
  <v-navigation-drawer
    class="SideBar"
    dark
    permanent
    fixed
    app
    :mini-variant="!showDrawer"
  >
    <a class="SideBar__logo-container" @click.prevent="toggleDrawer">
      <img
        src="@/assets/images/sh_logo.png"
        alt="Shift health logo"
        class="SideBar__logo"
      />
      <span class="SideBar__title">Shift Health</span>
    </a>

    <v-list nav class="SideBar__nav-menu d-flex flex-column">
      <v-expansion-panels v-model="openPanel" accordion>
        <v-expansion-panel v-for="(item, index) in links" :key="index">
          <v-expansion-panel-header v-if="item.isNested" hide-actions>
            <LinkItem :label="item.label" :icon="item.links[0].icon">
              <v-icon>mdi-chevron-down</v-icon>
            </LinkItem>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="item.isNested">
            <LinkItem
              v-for="(itemLink, index) in item.links"
              :key="index"
              :label="itemLink.label"
              :icon="itemLink.icon"
              :path="itemLink.path"
            />
          </v-expansion-panel-content>

          <v-expansion-panel-header v-else hide-actions>
            <LinkItem
              :path="item.link.path"
              :label="item.link.label"
              :icon="item.link.icon"
            />
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-list>

    <CustomerPicker :collapsed="!showDrawer" v-if="isShAdmin" />
  </v-navigation-drawer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import lodashGet from 'lodash/get';
import CustomerPicker from '@/components/customer-picker/customer-picker.vue';
import LinkItem from '@/components/sidebar/link-item.vue';
import linkGroups from '@/components/sidebar/linkGroups.json';

const { mapActions: sharedActions, mapGetters: sharedGetters } =
  createNamespacedHelpers('shared');
const { mapGetters: customerGetters, mapActions: customerActions } =
  createNamespacedHelpers('customer');

export default {
  name: 'SideBar',
  components: {
    CustomerPicker,
    LinkItem,
  },
  data() {
    return {
      visible: true,
      customersUsingPatientTab: [0, 1, 2, 4, 5],
      linkExpanded: [],
      openPanel: null,
    };
  },
  computed: {
    ...sharedGetters({
      showDrawer: 'SHOW_DRAWER',
    }),
    ...customerGetters({
      customer: 'CUSTOMER',
      user: 'USER',
    }),
    isShAdmin() {
      return lodashGet(this.customer, 'customer_id') === 0;
    },
    links() {
      const linkLists = [];
      const isPrivilegedUser = this.user && this.user?.is_privileged;

      linkGroups.forEach((linkGroup) => {
        if (!linkGroup.isShAdmin && !linkGroup.isPrivileged) {
          linkLists.push(linkGroup);
        }
        if (linkGroup.isShAdmin && this.isShAdmin) {
          linkLists.push(linkGroup);
        }
        if (linkGroup.isPrivileged && isPrivilegedUser) {
          linkLists.push(linkGroup);
        }
      });

      return linkLists;
    },
  },
  methods: {
    ...sharedActions(['toggleSidebar']),
    ...customerActions(['getFeatureFlags']),
    toggleDrawer() {
      this.toggleSidebar(!this.showDrawer);
    },
  },
};
</script>

<style lang="scss">
@import './sidebar';
</style>
